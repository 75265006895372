import chroma from "chroma-js";
import { Utils } from "../General/Utils";
export var StyleUtils;
(function (StyleUtils) {
    function getDefaultIfUndefined(value, defaultValue) {
        return value !== undefined ? value : defaultValue;
    }
    StyleUtils.getDefaultIfUndefined = getDefaultIfUndefined;
    function getCssPixelString(value) {
        return typeof value === "number" ? `${value}px` : value ? value : "";
    }
    StyleUtils.getCssPixelString = getCssPixelString;
    function getCssRemString(value) {
        return typeof value === "number" ? `${value}rem` : value ? value : "";
    }
    StyleUtils.getCssRemString = getCssRemString;
    //Deprecated
    function getCssPropertyFromArray(value) {
        let returnString = "";
        const isArray = Array.isArray(value);
        if (isArray) {
            const allUndefined = value.every(element => element === undefined);
            if (allUndefined) {
                return returnString;
            }
            const typeArray = value.map(element => typeof element);
            const hasNumber = typeArray.includes("number");
            const hasString = typeArray.includes("string");
            if (hasNumber && hasString) {
                console.error(`Css configuration array cannot have both string and number. Config: ${value}`);
                return returnString;
            }
            const undefinedValue = hasNumber ? 0 : "";
            const top = value[0] || undefinedValue;
            const right = value[1] || undefinedValue;
            const bottom = value[2] || undefinedValue;
            const left = value[3] || undefinedValue;
            returnString = `${getCssPixelString(top)} ${getCssPixelString(right)} ${getCssPixelString(bottom)} ${getCssPixelString(left)}`;
        }
        else {
            returnString = getCssPixelString(value);
        }
        return returnString;
    }
    StyleUtils.getCssPropertyFromArray = getCssPropertyFromArray;
    const luminanceChange = 10;
    const opacity = 35;
    StyleUtils.tableGenerateAlternatingRowColor = (colorHex, rowIndex) => {
        const isEven = rowIndex % 2 === 0;
        if (!colorHex) {
            console.error(`Color is null or undefined.`);
            return StyleUtils.ERROR_COLOR;
        }
        let colorHexWithOpacity = updateOpacity(colorHex, opacity).hex();
        let colorHexWithOpacityAndAlternating = colorHexWithOpacity;
        if (isEven) {
            const originalLuminance = chroma(colorHexWithOpacity).luminance();
            const tableLuminanceChange = luminanceChange > 50 ? 0.5 : luminanceChange / 100;
            let finalLuminance = originalLuminance;
            if (originalLuminance <= 0.5) {
                finalLuminance = originalLuminance + tableLuminanceChange;
            }
            else {
                finalLuminance = originalLuminance - tableLuminanceChange;
            }
            colorHexWithOpacityAndAlternating = chroma(colorHexWithOpacity)
                .luminance(finalLuminance)
                .hex();
        }
        return colorHexWithOpacityAndAlternating || "";
    };
    function updateOpacity(colorHex, opacity) {
        if (typeof colorHex === "undefined" || colorHex === "") {
            console.warn(`Invalid color : ${colorHex} for setting opacity.`);
            return;
        }
        let chromaScale = chroma.scale(["white", chroma(colorHex)]).domain([0, 100]);
        return chromaScale(opacity);
    }
    StyleUtils.updateOpacity = updateOpacity;
    function getRgbaHex(colorHex, opacity) {
        const opacityNumber = Math.floor(opacity * 255);
        const opacityString = opacityNumber.toString(16);
        return `${colorHex}${opacityString}`;
    }
    StyleUtils.getRgbaHex = getRgbaHex;
    StyleUtils.INVENTORY_TABLE_COLORS = {
        UNVERIFIED: "#A0A0A0",
        QUARANTINED: "#6464FF",
        IN_TEST: "#32BEDC",
        AVAILABLE: "#64E664",
        CHECKED_OUT: "#F0F064",
        INSTALLED: "#FFFFFF",
        MISSING: "#C887FA",
        DAMAGED: "#FF3232",
        UNAVAILABLE: "#A06E46",
        CREATED_IN_ERROR: "#FFFFC8",
        RMA: "#F0C864",
        REMOVED: "#1EA09B",
        SCRAPPED: "#FFC8C8"
    };
    StyleUtils.ERROR_COLOR = "#FF00FF";
    StyleUtils.INVENTORY_TABLE_ROW_HEIGHT_MULTIPLIER = 2;
    StyleUtils.INVENTORY_TABLE_TOP_BORDER_WIDTH_MULTIPLIER = 0.1;
    StyleUtils.TEXT_UNSELECTABLE_STYLE = {
        userSelect: "none"
    };
    function getNormalizedFontSize(size) {
        return (size / 10) * Utils.getRootFontSize();
    }
    StyleUtils.getNormalizedFontSize = getNormalizedFontSize;
    function getNormalizedPixelString(size) {
        if (typeof size === "string") {
            return size;
        }
        else {
            return `${getNormalizedFontSize(size)}px`;
        }
    }
    StyleUtils.getNormalizedPixelString = getNormalizedPixelString;
    function addNormalizedPixelString(sizeA, sizeB) {
        const isNumberA = typeof sizeA === "number";
        const isNumberB = typeof sizeB === "number";
        if (isNumberA && isNumberB) {
            const numberA = sizeA;
            const numberB = sizeB;
            const sum = numberA + numberB;
            return getNormalizedPixelString(sum);
        }
        else {
            const stringA = getNormalizedPixelString(sizeA);
            const stringB = getNormalizedPixelString(sizeB);
            return `calc(${stringA} + ${stringB})`;
        }
    }
    StyleUtils.addNormalizedPixelString = addNormalizedPixelString;
    function subtractNormalizedPixelString(sizeA, sizeB) {
        const isNumberA = typeof sizeA === "number";
        const isNumberB = typeof sizeB === "number";
        if (isNumberA && isNumberB) {
            const numberA = sizeA;
            const numberB = sizeB;
            const sum = numberA - numberB;
            return getNormalizedPixelString(sum);
        }
        else {
            const stringA = getNormalizedPixelString(sizeA);
            const stringB = getNormalizedPixelString(sizeB);
            return `calc(${stringA} - ${stringB})`;
        }
    }
    StyleUtils.subtractNormalizedPixelString = subtractNormalizedPixelString;
    function getNormalizedArrayPixelString(size) {
        if (Array.isArray(size)) {
            const length = size.length;
            switch (length) {
                case 1:
                    const all = getNormalizedPixelString(size[0]);
                    return `${all} ${all} ${all} ${all}`;
                case 2:
                    const topBottom2 = getNormalizedPixelString(size[0]);
                    const leftRight2 = getNormalizedPixelString(size[1]);
                    return `${topBottom2} ${leftRight2} ${topBottom2} ${leftRight2}`;
                case 3:
                    const top3 = getNormalizedPixelString(size[0]);
                    const leftRight3 = getNormalizedPixelString(size[1]);
                    const bottom3 = getNormalizedPixelString(size[2]);
                    return `${top3} ${leftRight3} ${bottom3} ${leftRight3}`;
                case 4:
                    const top4 = getNormalizedPixelString(size[0]);
                    const right4 = getNormalizedPixelString(size[1]);
                    const bottom4 = getNormalizedPixelString(size[2]);
                    const left4 = getNormalizedPixelString(size[3]);
                    return `${top4} ${right4} ${bottom4} ${left4}`;
                default:
                    console.log(`Array of length ${length}: ${Array.toString()} is not supported.`);
                    return "0px 0px 0px 0px";
            }
        }
        else {
            const all = getNormalizedPixelString(size || 0);
            return `${all} ${all} ${all} ${all}`;
        }
    }
    StyleUtils.getNormalizedArrayPixelString = getNormalizedArrayPixelString;
    function getTopBottomPixelNumber(size) {
        if (Array.isArray(size)) {
            const length = size.length;
            switch (length) {
                case 1:
                case 2:
                    return size[0] * 2;
                case 3:
                case 4:
                    return size[0] + size[2];
                default:
                    console.log(`Array of length ${length}: ${Array.toString()} is not supported.`);
                    return 0;
            }
        }
        else {
            return size * 2;
        }
    }
    StyleUtils.getTopBottomPixelNumber = getTopBottomPixelNumber;
    function getLeftRightPixelNumber(size) {
        if (Array.isArray(size)) {
            const length = size.length;
            switch (length) {
                case 1:
                case 2:
                    return size[1] * 2;
                case 3:
                    return size[1];
                case 4:
                    return size[1] + size[3];
                default:
                    console.log(`Array of length ${length}: ${Array.toString()} is not supported.`);
                    return 0;
            }
        }
        else {
            return size * 2;
        }
    }
    StyleUtils.getLeftRightPixelNumber = getLeftRightPixelNumber;
    function getRandomColor() {
        return '#' + (Math.random() * 0xFFFFFF << 0).toString(16);
    }
    StyleUtils.getRandomColor = getRandomColor;
})(StyleUtils || (StyleUtils = {}));
