import React, { useContext, useEffect, useState } from "react";
import ReactDOM from "react-dom";
import TextButton from "../../../display/web/components/Button/TextButton/TextButton";
import { ThemeContext } from "../../../display/theme/ThemeContextProvider";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import ManagePageUserFilterWindowContainer from "../../../display/web/containers/ManagePageUserFilterWindowContainer";
import { LegacyScriptLoader } from "../../helper/LegacyScriptLoader";
import { DispatchPageContainerActionTypes } from "../../../state/containers/DispatchPageContainer/types";
import ManagePageCalendarFilterWindowContainer from "../../../display/web/containers/ManagePageCalendarFilterWindowContainer";
import { ManagePageContainerActionTypes } from "../../../state/containers/ManagePageContainer/types";
import ManagePageVehicleLinkWindowContainer from "../../../display/web/containers/ManagePageVehicleLinkWindowContainer";
import ManagePageVehicleUnlinkWindowContainer from "../../../display/web/containers/ManagePageVehicleUnlinkWindowContainer";
import Traccar from "../../../state/api/REST/Traccar/Traccar";
import DOMPurify from 'dompurify';
var html = require("./index.html");
var page = { __html: DOMPurify.sanitize(html) };
const ManagePage = (props) => {
    const [displayOrderFilterButton, setDisplayOrderFilterButton] = useState(false);
    const [orderFilterButtonElement, setOrderFilterButtonElement] = useState(null);
    const [orderFilterName, setOrderFilterName] = useState("");
    const [displayFsrFilterButton, setDisplayFsrFilterButton] = useState(false);
    const [fsrFilterButtonElement, setFsrFilterButtonElement] = useState(null);
    const [fsrFilterName, setFsrFilterName] = useState("");
    const [displayAvlFilterButton, setDisplayAvlFilterButton] = useState(false);
    const [avlFilterButtonElement, setAvlFilterButtonElement] = useState(null);
    const [avlFilterName, setAvlFilterName] = useState("");
    const [filterType, setFilterType] = useState("order");
    const [displayUserFilterWindow, setDisplayUserFilterWindow] = useState(false);
    const [displayCalendarFilterWindow, setDisplayCalendarFilterWindow] = useState(false);
    const [displayVehicleLinkWindow, setDisplayVehicleLinkWindow] = useState(false);
    const [displayVehicleUnlinkWindow, setDisplayVehicleUnlinkWindow] = useState(false);
    const dispatchDispatch = useDispatch();
    const manageDispatch = useDispatch();
    const dispatchState = useSelector(store => store.dispatch, shallowEqual);
    const { theme: contextTheme } = useContext(ThemeContext);
    const theme = contextTheme;
    useEffect(() => {
        //@ts-ignore
        window.pageType = "legacy-manage";
        import("./scripts")
            .then(() => {
            LegacyScriptLoader.loadScripts([
                "/sl3/scripts/jquery.min.js",
                "/sl3/scripts/jquery-ui.custom.min.js",
                "/sl3/scripts/jquery.cookie.min.js",
                "/sl3/scripts/jquery-cron-quartz.min.js",
                "/sl3/scripts/jquery-ui-timepicker-addon.js",
                "/sl3/scripts/jquery.xml2json.js",
                "/sl3/scripts/underscore-min.js",
                "/sl3/scripts/moment.min.js",
                "/sl3/scripts/fullcalendar.js",
                "/sl3/scripts/dateformats.js",
                "/sl3/scripts/mousewheel.js",
                "/sl3/scripts/jquery.multiple.check.select.js",
                "/sl3/scripts/ol.js",
                "/sl3/scripts/ol-contextmenu.js",
                "/sl3/scripts/manage-functions-jobschedule.js",
                "/sl3/scripts/ol-ext.min.js",
                "/sl3/scripts/sl-common.js",
                "/sl3/scripts/dispatch-functions.js",
                "/sl3/scripts/manage-tables.js",
                "/sl3/scripts/manage-functions.js",
                "/sl3/scripts/manage-calendar-slots.js",
                "/sl3/scripts/key_shortcut.js",
                "/sl3/scripts/eventsource.min.js",
                "/sl3/scripts/manage-scripts.js",
                "/sl3/scripts/manage-functions-locations.js",
                "/sl3/scripts/manage-functions-shifts.js",
                "/sl3/scripts/chosen.jquery.min.js",
                "/sl3/scripts/manage-functions-messages.js",
            ]).then(() => {
                Traccar.authenticate();
                //@ts-ignore
                window.sl.mountViewFilterButton = mountViewFilterButton;
                //@ts-ignore
                window.sl.unmountViewFilterButton = unmountViewFilterButton;
                //@ts-ignore
                window.sl.calendarFilterButtonClick = calendarFilterButtonClick;
                //@ts-ignore
                window.sl.vehicleLinkButtonClick = vehicleLinkButtonClick;
                //@ts-ignore
                window.sl.vehicleUnlinkButtonClick = vehicleUnlinkButtonClick;
                dispatchDispatch({
                    type: DispatchPageContainerActionTypes.INITIALIZE,
                    theme: theme
                });
            });
        });
    }, []);
    const vehicleLinkButtonClick = () => {
        setDisplayVehicleLinkWindow(true);
    };
    const vehicleUnlinkButtonClick = () => {
        setDisplayVehicleUnlinkWindow(true);
    };
    const calendarFilterButtonClick = (calendarName, filterName) => {
        manageDispatch({
            type: ManagePageContainerActionTypes.CALENDAR_FILTER_WINDOW_INIT,
            calendarName: calendarName,
            filterName: filterName,
        });
        setDisplayCalendarFilterWindow(true);
    };
    const mountViewFilterButton = (element, type) => {
        if (type === "order") {
            setOrderFilterButtonElement(element);
            setDisplayOrderFilterButton(true);
            const initialOrderFilterName = $(element).parent().find("#order_filter option:selected").attr("value");
            setOrderFilterName(initialOrderFilterName);
        }
        else if (type === "fsr") {
            setFsrFilterButtonElement(element);
            setDisplayFsrFilterButton(true);
            const initialFsrFilterName = $(element).parent().find("#fsr_filter option:selected").attr("value");
            setFsrFilterName(initialFsrFilterName);
        }
        else if (type === "avl") {
            setAvlFilterButtonElement(element);
            setDisplayAvlFilterButton(true);
            const initialAvlFilterName = $(element).parent().find("#avl_filter option:selected").attr("value");
            setAvlFilterName(initialAvlFilterName);
        }
    };
    const unmountViewFilterButton = (type) => {
        if (type === "order") {
            setDisplayOrderFilterButton(false);
        }
        else if (type === "fsr") {
            setDisplayFsrFilterButton(false);
        }
        else if (type === "avl") {
            setDisplayAvlFilterButton(false);
        }
    };
    const renderOrderFilterButton = () => {
        const filterSelect = $(orderFilterButtonElement).parent().find("#order_filter");
        const isEnabled = orderFilterName !== "";
        const buttonText = isEnabled ? "View or Edit Filter" : "No Filter Selected";
        const filterSelectElement = filterSelect[0];
        filterSelectElement.onchange = () => {
            const filterName = filterSelect.find("option:selected").attr("value");
            setOrderFilterName(filterName);
            if (filterName !== "") {
                const filterString = JSON.stringify(dispatchState.filterSettings.order.savedFilters.find(savedFilters => savedFilters.name === filterName).filter);
                const processedFilterString = encodeURIComponent(filterString.replace(/"/g, "\\\""));
                window.sl.thisObj.order_filter_name = filterName;
                window.sl.thisObj.order_filter = processedFilterString;
            }
            else {
                window.sl.thisObj.order_filter_name = filterName;
                window.sl.thisObj.order_filter = "";
            }
        };
        return (ReactDOM.createPortal((React.createElement(TextButton, { text: buttonText, margin: [0, 0, 0, 256 / 12 * 10], width: 135, borderRadius: 5, borderWidth: 0, color: { normal: "#FFFFFF", mouseOver: "#FFFFFF", mouseDown: "#FFFFFF", disabled: "#888888" }, backgroundColor: { normal: "#0078d4", mouseOver: "#0078d4", mouseDown: "#0078d4", disabled: "#AAAAAA" }, handleClick: handleOrderFilterButtonClick, isEnabled: isEnabled })), orderFilterButtonElement));
    };
    const renderFsrFilterButton = () => {
        const filterSelect = $(fsrFilterButtonElement).parent().find("#fsr_filter");
        const isEnabled = fsrFilterName !== "";
        const buttonText = isEnabled ? "View or Edit Filter" : "No Filter Selected";
        const filterSelectElement = filterSelect[0];
        filterSelectElement.onchange = () => {
            const filterName = filterSelect.find("option:selected").attr("value");
            setFsrFilterName(filterName);
            if (filterName !== "") {
                const filterString = JSON.stringify(dispatchState.filterSettings.fsr.savedFilters.find(savedFilters => savedFilters.name === filterName).filter);
                const processedFilterString = encodeURIComponent(filterString.replace(/"/g, "\\\""));
                window.sl.thisObj.fsr_filter_name = filterName;
                window.sl.thisObj.fsr_filter = processedFilterString;
            }
            else {
                window.sl.thisObj.fsr_filter_name = filterName;
                window.sl.thisObj.fsr_filter = "";
            }
        };
        return (ReactDOM.createPortal((React.createElement(TextButton, { text: buttonText, margin: [0, 0, 0, 256 / 12 * 10], width: 135, borderRadius: 5, borderWidth: 0, color: { normal: "#FFFFFF", mouseOver: "#FFFFFF", mouseDown: "#FFFFFF", disabled: "#888888" }, backgroundColor: { normal: "#0078d4", mouseOver: "#0078d4", mouseDown: "#0078d4", disabled: "#AAAAAA" }, handleClick: handleFsrFilterButtonClick, isEnabled: isEnabled })), fsrFilterButtonElement));
    };
    const renderAvlFilterButton = () => {
        const filterSelect = $(orderFilterButtonElement).parent().find("#avl_filter");
        const isEnabled = avlFilterName !== "";
        const buttonText = isEnabled ? "View or Edit Filter" : "No Filter Selected";
        const filterSelectElement = filterSelect[0];
        filterSelectElement.onchange = () => {
            const filterName = filterSelect.find("option:selected").attr("value");
            setAvlFilterName(filterName);
            if (filterName !== "") {
                const filterString = JSON.stringify(dispatchState.filterSettings.vehicle.savedFilters.find(savedFilters => savedFilters.name === filterName).filter);
                const processedFilterString = encodeURIComponent(filterString.replace(/"/g, "\\\""));
                window.sl.thisObj.avl_filter_name = filterName;
                window.sl.thisObj.avl_filter = processedFilterString;
            }
            else {
                window.sl.thisObj.avl_filter_name = filterName;
                window.sl.thisObj.avl_filter = "";
            }
        };
        return (ReactDOM.createPortal((React.createElement(TextButton, { text: buttonText, margin: [0, 0, 0, 256 / 12 * 10], width: 135, borderRadius: 5, borderWidth: 0, color: { normal: "#FFFFFF", mouseOver: "#FFFFFF", mouseDown: "#FFFFFF", disabled: "#888888" }, backgroundColor: { normal: "#0078d4", mouseOver: "#0078d4", mouseDown: "#0078d4", disabled: "#AAAAAA" }, handleClick: handleAvlFilterButtonClick, isEnabled: isEnabled })), avlFilterButtonElement));
    };
    const renderUserFilterWindow = () => {
        const filterTypeNameMap = {
            order: orderFilterName,
            fsr: fsrFilterName,
            avl: avlFilterName,
        };
        const filterName = filterTypeNameMap[filterType];
        return (React.createElement(ManagePageUserFilterWindowContainer, { open: displayUserFilterWindow, filterName: filterName, filterType: filterType, handleClose: handleFilterWindowClose }));
    };
    const handleOrderFilterButtonClick = () => {
        setFilterType("order");
        setDisplayUserFilterWindow(true);
    };
    const handleFsrFilterButtonClick = () => {
        setFilterType("fsr");
        setDisplayUserFilterWindow(true);
    };
    const handleAvlFilterButtonClick = () => {
        setFilterType("avl");
        setDisplayUserFilterWindow(true);
    };
    const handleFilterWindowClose = () => {
        setDisplayUserFilterWindow(false);
    };
    const handleVehicleLinkWindowClose = () => {
        setDisplayVehicleLinkWindow(false);
    };
    const handleVehicleUnlinkWindowClose = () => {
        setDisplayVehicleUnlinkWindow(false);
    };
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: "manage-page", dangerouslySetInnerHTML: page, style: { height: "100%", width: "100%" } }),
        displayVehicleUnlinkWindow ? React.createElement(ManagePageVehicleUnlinkWindowContainer, { open: displayVehicleUnlinkWindow, handleClose: handleVehicleUnlinkWindowClose }) : null,
        displayVehicleLinkWindow ? React.createElement(ManagePageVehicleLinkWindowContainer, { open: displayVehicleLinkWindow, handleClose: handleVehicleLinkWindowClose }) : null,
        displayCalendarFilterWindow ? React.createElement(ManagePageCalendarFilterWindowContainer, null) : null,
        displayUserFilterWindow ? renderUserFilterWindow() : null,
        displayOrderFilterButton && orderFilterButtonElement ? (renderOrderFilterButton()) : null,
        displayFsrFilterButton && fsrFilterButtonElement ? (renderFsrFilterButton()) : null,
        displayAvlFilterButton && avlFilterButtonElement ? (renderAvlFilterButton()) : null));
};
export default ManagePage;
